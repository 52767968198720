import React from "react"
import Content from '../templates/content'
import { graphql } from "gatsby"
import Portfolio from '../components/Layout/portfolio'
import SEO from '../components/Navigation/seo'
import { useIntl } from "gatsby-plugin-intl"

const PortfolioPage = ({data}) =>{
	const intl = useIntl();
	return (
		<Content h1={intl.formatMessage({ id: "portfolio.title" })} portfolio>
			<SEO
				lang={intl.locale}
				title={intl.formatMessage({ id: "portfolio.seoTitle" })}
				description={intl.formatMessage({ id: "portfolio.seoDesc" })}
			/>
			<Portfolio files={data.allFile.nodes} />
		</Content>
	)
}

export const query = graphql`
	{
		allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "portfolio"}}) {
			nodes {
				childImageSharp {
					fluid(maxWidth: 1920, quality: 90) {
						...GatsbyImageSharpFluid_withWebp_noBase64
						presentationWidth
						originalName
					}
				}
			}
		}
	}
`;


export default PortfolioPage
