import {techHtml, techJs, techDev, techWp } from './technologies-list'

const techStandard = Object.assign({}, techHtml, {javascript:techJs['javascript']}, techDev);
const techWoocommerce = Object.assign(
	{},
	techHtml,
	{javascript:techJs['javascript']},
	techDev,
	techWp
);
const techWordpress = Object.assign(
	{},
	techHtml,
	{javascript:techJs['javascript']},
	techDev,
	{php:techWp['php']},
	{wordpress:techWp['wordpress']},
	{acf:techWp['acf']},
);


const portfolioList = [
	{
		name: 'blessmusic',
		title: 'Bless Music',
		subtitle: 'portal muzyczny z płatnymi tutorialami - indywidualny szablon dla woocommerce zbudowany od podstaw',
		color: '#e5006d',
		tech: techWoocommerce,
		url: 'https://blessmusic.pl/'
	},
	{
		name: 'aslan',
		title: 'OPK Aslan',
		subtitle: 'identyfikacja wizualna firmy (logo, ulotki), wykonanie strony spójnej z identyfikacją',
		color: '#536091',
		tech: techStandard,
		url: 'https://opk-aslan.com/'
	},
	{
		name: 'positivecare',
		title: 'Positivecare',
		subtitle: 'sklep internetowy oparty na WooCommerce, własny szablon dostosowany do identyfikacji wizualnej firmy',
		color: '#b62b42',
		tech: techWoocommerce,
		url: 'https://positivecare.pl/'
	},
	{
		name: 'jkamplifiers',
		title: 'JK Amplifiers',
		subtitle: 'zakładki z wykorzystaniem History API, edycja promocyjnego filmiku',
		color: '#ff641f',
		tech: techStandard,
		url: 'https://jkamplifiers.com/'
	},
	{
		name: 'naszeinspiracje',
		title: 'Nasze Inspiracje',
		subtitle: 'magazyn online, rozbudowane kategorie oraz wewnętrzny system wyświetlania reklam',
		color: '#5c82cc',
		tech: techWordpress,
		url: 'https://naszeinspiracje.com/'
	},
	{
		name: 'drzeworadosci',
		title: 'Drzewo Radości',
		subtitle: 'sklep internetowy z klasami wysyłkowymi i szybką płatnością',
		color: '#d28e77',
		tech: techWoocommerce,
		url: 'https://drzeworadosci.pl/'
	},
	{
		name: 'introfast',
		title: 'Introfast',
		subtitle: 'projekt loga i wykonanie strony z ciekawym przejściem pomiędzy zakładkami',
		color: '#f4d629',
		tech: techStandard,
		url: 'https://introfast.pl/'
	},
	{
		name: 'missbee',
		title: 'Miss Bee',
		subtitle: 'identyfikacja wizualna i wdrożenie bloga, projekt i wykonanie szablonu dla WordPress',
		color: '#ce9079',
		tech: techWordpress,
		url: 'https://missbee.pl/'
	},
	{
		name: 'everest',
		title: 'Everest Stolarka',
		subtitle: 'nowoczesny format obrazków (WebP), lazy load - opóźnione ładowanie zdjęć i obrazów',
		color: '#2091d3',
		tech: techStandard,
		url: 'http://everest-stolarka.pl/'
	},
	{
		name: 'fot',
		title: 'Fountain of Tears',
		subtitle: 'strona typu single page, strona w trzech językach, obsługa języka pisanego z prawej do lewej',
		color: '#fde7d0',
		tech: techStandard,
		url: 'https://fountainoftears.org/'
	},
	{
		name: 'terapiakosciuk',
		title: 'Terapia Kościuk',
		subtitle: 'odświeżenie loga i stworzenie wersji wektorowej, projekt i wykonanie strony spójnej z logiem firmy',
		color: '#6791cd',
		tech: techStandard,
		url: 'https://terapiakosciuk.pl/'
	}
]

export default portfolioList;