import React from "react"
import { H2, H3, Row, Col, Ul, Li } from '@bootstrap-styled/v4';
import websites from '../../data/portfolio-list'
import classNames from 'classnames'
import { GbImageMw } from '../../components/Images/GatsbyImage'
import styled from 'styled-components'
import bp from '../../assets/styles/breakpoints'
import IconExternalLink from '../../../static/assets/icons/external-link.inline.svg'
import BackgroundImage from 'gatsby-background-image'
import { motion } from 'framer-motion'
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"

const BackgroundSection = ({ children, className, imageData }) => (
		<BackgroundImage
			Tag="div"
			className={className}
			fluid={imageData}
			fadeIn={false} 
  		loading="eager"
		>
			{children}
		</BackgroundImage>
)

const StyledBackgroundSection = styled(BackgroundSection)`
	width: 100%;
	background-position: bottom right;
	background-repeat: no-repeat;
	background-size: contain;
	padding: 2rem 0 0;
	${bp.up('lg')}{
		padding: 4rem 0;
	}
`

const StyledGbImageMW = styled(GbImageMw)`
	display: block;
	${bp.up('lg')}{
		margin-left: -5vw;
	}
`

const Technologies = styled.div`
	padding: 2rem;
	margin-top: 1rem;
	ul{
		max-width: 18rem;
	}
	li{
		display: inline-block;
		width: 2rem;
		img{
			max-width: 100%;
			height: auto;
		}
		&:not(:last-child){
			margin-right: 0.5rem;
		}
	}
	${bp.up('lg')}{
		padding: 3rem;
		margin-top: 8rem;
	}
	${bp.up('xl')}{
		margin-top: 14rem;
	}
	${bp.up('xxl')}{
		padding: 4rem;
		margin-top: 16rem;
	}
	${bp.down('md')}{
		margin: 0 0 0 12vw;
		padding: 1.5rem;
		h3{
			font-size: 1rem;
		}
	}
`

const TitleBox = styled.div`
	color: #000;
	display: flex;
	div{
		width: 10vw;
		height: 4vw;
		margin-right: 2vw;
		margin-top: 1.5vw;
		max-height: 5rem;
		flex: 0 0 10vw;
	}
	svg{
		fill: #fff;
		width: 2rem;
		height: 2rem;
		margin-left: 1rem;
		transition: transform 0.3s, opacity 0.3s;
		transform: translateX(-1rem);
		opacity: 0;
		${bp.up('xl')}{
			width: 3rem;
			height: 3rem;
		}
	}
	&:hover svg{
		transform: translateX(0);
		opacity: 1;
	}
	${bp.up('lg')}{
		margin-left: -20vw;
	}
	${bp.down('md')}{
		background: linear-gradient(to top, #202020, #202020 50%, transparent);
		padding: 1.5rem 1.5rem 0 1.5rem;
	}
	${bp.down('md')}{
		div{
			margin-top: 2vw;
		}
	}
`

const Subtitle = styled.span`
	display: block;
	font-size: 1rem;
	font-weight: 400;
	max-width: 22vw;
	text-shadow: 1px 1px 0 rgba(32,32,32,0.85);
	line-height: 1.4;
	${bp.up('xxl')}{
		font-size: 1.5rem;
	}
	${bp.down('md')}{
		max-width: unset;
	}
`

const Separator = styled.div`
	width: 50%;
	max-width: 10rem;
	height: 0.5rem;
	margin: 1rem 0;
	${bp.down('md')}{
		height: 0.15rem;
	}
`


const Portfolio = ({files}) => {
	const intl = useIntl();
	return (<>
		{websites.map(el =>{
			const index = files.findIndex(file => file.childImageSharp.fluid.originalName === (el.name + '.png'));
			const bgIndex = files.findIndex(file => file.childImageSharp.fluid.originalName === (el.name + '-bg.jpg'));
			return <Row className={classNames("portfolio-box", 'my-2 my-md-3', el.name)} key={el.name}>
				<Col lg="3" className="left">
					<Technologies>
						<H3><FormattedMessage id="portfolio.tech" /></H3>
						<Separator style={{backgroundColor:el.color}}></Separator>
						<Ul>
							{Object.keys(el.tech).map((tech, index) => (
								<Li key={index}><img src={el.tech[tech].src} alt={tech} /></Li>
							))}
						</Ul>
					</Technologies>
				</Col>
				<Col lg="9" className="right">
					<StyledBackgroundSection imageData={[`linear-gradient(rgb(32, 32, 32), rgba(32, 32, 32, 0.65))`, files[bgIndex].childImageSharp.fluid]}>
						<a href={el.url}>
							<motion.div initial={{scale: 0.96}} whileHover={{scale: 1}} transition={{ duration: 0.5 }}>
								<StyledGbImageMW fluid={files[index].childImageSharp.fluid}/>
							</motion.div>
							<TitleBox>
								<div style={{backgroundColor:el.color}}></div>
								<H2 className="resFont-big">
									<FormattedMessage id={'portfolio.list.'+el.name+'.title'} />
									<Subtitle>{intl.formatMessage({ id: 'portfolio.list.'+el.name+'.subtitle' }).replace(/(\s)([\S])[\s]+/g, "$1$2\u00a0")}</Subtitle>
								</H2>
								<IconExternalLink />
							</TitleBox>
						</a>
					</StyledBackgroundSection>
				</Col>
			</Row>
		})}
	</>)
};

export default Portfolio